module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Medrar Logopedia y Aprendizaje","lang":"es","description":"MEDRAR Logopedia y Aprendizaje. Centro de atención logopédica en A Coruña.","short_name":"Medrar","start_url":"/","background_color":"#ffffff","theme_color":"#38b6ab","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"95a36131874cb777c9a4cb8574fac838"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-H327WW77J6","cookieName":"centromedrar-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-WZ3JFT5","cookieName":"centromedrar-gdpr-google-tagmanager","dataLayerName":"dataLayer","defaultDataLayer":{"platform":"gatsby"}},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
