exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-condiciones-del-servicio-index-tsx": () => import("./../../../src/pages/condiciones-del-servicio/index.tsx" /* webpackChunkName: "component---src-pages-condiciones-del-servicio-index-tsx" */),
  "component---src-pages-contacto-index-tsx": () => import("./../../../src/pages/contacto/index.tsx" /* webpackChunkName: "component---src-pages-contacto-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-metodologia-index-tsx": () => import("./../../../src/pages/metodologia/index.tsx" /* webpackChunkName: "component---src-pages-metodologia-index-tsx" */),
  "component---src-pages-politica-de-privacidad-y-cookies-index-tsx": () => import("./../../../src/pages/politica-de-privacidad-y-cookies/index.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-y-cookies-index-tsx" */),
  "component---src-pages-promocion-empleo-autonomo-index-tsx": () => import("./../../../src/pages/promocion-empleo-autonomo/index.tsx" /* webpackChunkName: "component---src-pages-promocion-empleo-autonomo-index-tsx" */),
  "component---src-pages-servicios-index-tsx": () => import("./../../../src/pages/servicios/index.tsx" /* webpackChunkName: "component---src-pages-servicios-index-tsx" */),
  "component---src-pages-sobre-mi-index-tsx": () => import("./../../../src/pages/sobre-mi/index.tsx" /* webpackChunkName: "component---src-pages-sobre-mi-index-tsx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-apego-y-desarrollo-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/apego-y-desarrollo.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-apego-y-desarrollo-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-apego-y-desarrollo-que-es-el-apego-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/apego-y-desarrollo/que-es-el-apego.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-apego-y-desarrollo-que-es-el-apego-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-apego-y-desarrollo-tipos-de-apego-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/apego-y-desarrollo/tipos-de-apego.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-apego-y-desarrollo-tipos-de-apego-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-desarrollo-comunicativo-linguistico-0-24-meses-etapa-linguistica-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/desarrollo-comunicativo-linguistico-0-24-meses/etapa-linguistica.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-desarrollo-comunicativo-linguistico-0-24-meses-etapa-linguistica-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-desarrollo-comunicativo-linguistico-0-24-meses-etapa-prelinguistica-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/desarrollo-comunicativo-linguistico-0-24-meses/etapa-prelinguistica.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-desarrollo-comunicativo-linguistico-0-24-meses-etapa-prelinguistica-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-desarrollo-comunicativo-linguistico-0-24-meses-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/desarrollo-comunicativo-linguistico-0-24-meses.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-desarrollo-comunicativo-linguistico-0-24-meses-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-evaluacion-logopedica-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/evaluacion-logopedica.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-evaluacion-logopedica-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-comprension-lectora-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras/comprension-lectora.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-comprension-lectora-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-conciencia-fonologica-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras/conciencia-fonologica.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-conciencia-fonologica-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-grafomotricidad-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras/grafomotricidad.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-grafomotricidad-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-procesamiento-lexico-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras/procesamiento-lexico.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-procesamiento-lexico-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-procesamiento-sintactico-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras/procesamiento-sintactico.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-procesamiento-sintactico-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-reconocimiento-de-letras-o-conversion-grafema-fonema-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/habilidades-prelectoras/reconocimiento-de-letras-o-conversion-grafema-fonema.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-habilidades-prelectoras-reconocimiento-de-letras-o-conversion-grafema-fonema-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-pautas-para-instaurar-los-habitos-de-estudio-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/pautas-para-instaurar-los-habitos-de-estudio.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-pautas-para-instaurar-los-habitos-de-estudio-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-pautas-simples-para-estimular-el-lenguaje-en-casa-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/pautas-simples-para-estimular-el-lenguaje-en-casa.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-pautas-simples-para-estimular-el-lenguaje-en-casa-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-peques-y-pantallas-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/peques-y-pantallas.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-peques-y-pantallas-mdx" */),
  "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-que-es-un-logopeda-mdx": () => import("./../../../src/templates/article/article.tsx?__contentFilePath=/Users/jabalde/devel/personal/medrar-gatsby/src/data/quieres-saber-mas/que-es-un-logopeda.mdx" /* webpackChunkName: "component---src-templates-article-article-tsx-content-file-path-users-jabalde-devel-personal-medrar-gatsby-src-data-quieres-saber-mas-que-es-un-logopeda-mdx" */)
}

